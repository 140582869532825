.card {
  @apply rounded-lg relative text-center pb-4 flex flex-col items-center;
}

.card > span {
  @apply !w-full;
}

.shadow {
  box-shadow: 0px 5px 20px #00147714;
}

.bord {
  @apply border border-gray/50 rounded-xl overflow-hidden;
}

.title {
  @apply mt-4 mx-6 mb-3 uppercase font-display text-blue text-lg;
}

.body {
  @apply mx-6 mb-4 text-xs font-light text-gray;
}

.card .cta {
  @apply mt-auto;
}
