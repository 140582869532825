.wrap {
  @apply flex items-center w-96;
}

.image {
  @apply w-1/2 mr-2 h-40 max-w-2xl relative block;
}

.content {
  @apply w-1/2 text-center;
}

.title {
  @apply text-xs underline underline-offset-4 decoration-gray-200 uppercase text-blue font-medium;
}

.cta {
  @apply text-green flex mt-2 items-center text-xs justify-center leading-none;
}

.cta span {
  @apply block ml-2;
}
