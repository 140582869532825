.heading div {
  @apply text-center;
}

.heading h1 {
  @apply mb-4;
}

.heading h2 {
  @apply leading-tight;
}

.intro {
  @apply my-8;
}

.intro_txt {
  @apply sm:columns-2 sm:gap-8 md:gap-12;
}

.content {
  @apply grid sm:grid-cols-2 lg:grid-cols-6 gap-4 md:gap-6 lg:gap-8 my-8;
}
.content > span,
.content > div {
  @apply lg:col-span-2;
}
.content > div:nth-child(7) {
  @apply lg:col-start-2;
}

.badge {
  @apply flex px-6 py-4 items-center text-left justify-center;
}

.badge div {
  @apply ml-2;
}

.badge h4 {
  @apply text-2xl font-light font-body md:text-3xl text-blue leading-none mb-1;
}

.badge h5 {
  @apply text-lg font-light font-body md:text-xl text-gray-300 leading-none;
}

.download {
  @apply my-8 flex justify-center;
}
